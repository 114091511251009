import * as React from 'react';
import { PageHead } from '@app/components/PageHead';
import { ErrorView } from '@app/views/error';
import { PoweredBy } from '@app/components/PoweredBy';
import { Testimonial } from '@app/components/Testimonial';

export const Error: React.FC = () => (
  <>
    <PageHead title="Not Found" />
    <ErrorView />
    <Testimonial />
    <PoweredBy />
  </>
);

export default Error;
